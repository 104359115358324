import { getAttributes, getFootprint } from '@/api/image';
import { getImages, getImagesId } from '@/api/images';

import router from '@/router';

export const MapModes = {
  drag: 'drag',
  click: 'click',
};

const state = {
  map: {
    location: [8.224, 46.625], // Center on Switzerland by default
    zoom: 8,
    mode: MapModes.drag
  },
  mapOverlayFilters: [],
  images: [],
  nImages: null,
  imagesFilters: {
    limit: 30,
    offset: 0,
    bbox: [],
    owner_id: [],
    collection_id: [],
    date_shot_max: null,
    date_shot_min: null,
    keyword: null,
    place_names: [],
    latitude: null,
    longitude: null,
    sortKey: 'distance', // possible values: title, date_shot_min
    view_type: ['terrestrial', 'lowOblique', 'highOblique', 'nadir'],
    POI_latitude: 0,
    POI_longitude: 0,
    POI_MaxDistance: 2000
  },
  slider: {
    opened: true,
    currentId: null
  },
  currentImage: {
    title: null,
    caption: null,
    collection: {
      name: null,
      link: null
    },
    owner: {
      name: null,
      link: null
    },
    photographer: {
      name: null,
      link: null
    },
    license: null
  },
  currentPose: {
    latitude: null,
    longitude: null,
    altitude: null,
    azimuth: null,
    tilt: null,
    roll: null,
    focal: null,
    heightAboveGround: null,
    locationLocked: false,
    country_iso_a2: null,
    gltf_url: null,
    regionByPx: null
  },
  //CURRENTPOSES composite_image
  currentPoses: null,
  currentPanorama: {},
  views: {
    visit: [],
    contribute: []
  }
};

const getters = {
  images: (state) => {
    return state.images;
  },
  isCurrentImageLockedByOther: (state, dispatch, rootState) => {
    return state.currentImage.locked && (rootState.user.userInfo.id !== state.currentImage.locked_user_id || state.currentImage.locked_user_id === 14);
  },
  isCurrentImageLoaded: state => {
    return state.currentImage?.id !== null && typeof state.currentImage?.id !== 'undefined';
  },
  hasAttemptToGeoloc: (state, dispatch, rootState) => {
    return (rootState.geolocalisation.image_id === state.currentImage.id);
  },
  currentPanorama: state => { return state.currentPanorama; },
  isCurrentImageComposite: state => {
    if (state.currentImage.framing_mode === 'single_image') {
      return false;
    } else {
      return state.currentPoses ? true : false;
    }

  },
  mapOverlayFilters: state => { return state.mapOverlayFilters; },
  mapMode: state => { return state.map.mode; },
  currentOffsetFilter: state => { return state.imagesFilters.offset; },
  currentPOIDistanceFilter: state => { return state.imagesFilters.POI_MaxDistance; },
  currentPOI: state => { return [state.imagesFilters.POI_longitude, state.imagesFilters.POI_latitude ]; }
};

const mutations = {
  SET_CURRENT_IMAGE: (state, currentImage) => {
    state.currentImage = currentImage;
  },
  SET_POSE: (state, pose) => {
    state.currentPose = { ...state.currentPose, ...pose };
    // check values to prevent invalid values being userd in CesiumJS
    if (state.currentPose.roll && state.currentPose.roll > 360) {
      state.currentPose.roll = state.currentPose.roll % 360;
    } else if (state.currentPose.roll && state.currentPose.roll < -360) {
      state.currentPose.roll = state.currentPose.roll % 360;
      state.currentPose.roll = state.currentPose.roll + 360;
    }
  },
  //SET_POSES_panorama
  SET_POSES: (state, poses) => {
    state.currentPoses = poses;
    for (const pose of state.currentPoses) {
      // check values to prevent invalid values being userd in CesiumJS
      if (pose.roll && pose.roll > 360) {
        pose.roll = pose.roll % 360;
      } else if (pose.roll && pose.roll < -360) {
        pose.roll = pose.roll % 360;
        pose.roll = pose.roll + 360;
      }
    }
  },
  RESET_POSES: state => {
    state.currentPoses = null;
  },
  UPDATE_CURRENT_POSE: (state, geolocId) => {
    state.currentPose = state.currentPoses.find(element => element.geolocalisation_id === geolocId);
  },
  SET_CURRENT_PANORAMA: (state, poses) => {
    const img_width = state.currentImage.width;
    const panoramas = {
      imageId: state.currentImage.id,
      geolocId: [],
      images: [],
      rectangle: []
    };

    for (const pose of poses) {
      panoramas.geolocId.push(pose.geolocalisation_id);
      panoramas.images.push({
        x: pose.regionByPx[0],
        y: pose.regionByPx[1],
        width: pose.regionByPx[2],
        height: pose.regionByPx[3]
      });
      //convert images coordinates into viewport coordinates
      panoramas.rectangle.push({
        x: pose.regionByPx[0] / img_width,
        y: pose.regionByPx[1] / img_width,
        width: pose.regionByPx[2] / img_width,
        height: pose.regionByPx[3] / img_width
      });
    }
    state.currentPanorama = panoramas;
  },
  SET_CURRENT_IMAGE_FOOTPRINT: (state, footprint) => {
    state.currentImage.footprint = footprint;
  },
  DECREMENT_NOBS: state => {
    state.currentImage.nObs -= 1;
  },
  LOCK_CURRENT_IMAGE: state => {
    state.currentImage.locked = true;
  },
  UNLOCK_CURRENT_IMAGE: state => {
    state.currentImage.locked = false;
  },
  SET_LOCKED_USER_ID: (state, payload) => {
    state.currentImage.locked_user_id = payload;
  },
  SET_CURRENT_IMAGES: (state, images) => {
    state.images = images;
  },
  ADD_TO_CURRENT_IMAGES: (state, image) => {
    state.images.push(image);
  },
  RESET_CURRENT_IMAGES: (state) => {
    state.images = [];
    state.nImages = 0;
  },
  SET_CURRENT_IMAGES_NUMBER: (state, nImages) => {
    state.nImages = nImages;
  },
  SET_FILTER_OFFSET: (state, offset) => {
    state.imagesFilters.offset = offset;
  },
  SET_FILTER_BBOX: (state, bbox) => {
    const bboxFiltered = bbox.map(coord => parseFloat(coord.toFixed(3)));
    state.imagesFilters.bbox = bboxFiltered;
  },
  SET_FILTER_LATITUDE: (state, latitude) => {
    state.imagesFilters.latitude = latitude;
  },
  SET_FILTER_LONGITUDE: (state, longitude) => {
    state.imagesFilters.longitude = longitude;
  },
  SET_FILTER_LIMIT: (state, limit) => {
    state.imagesFilters.limit = limit;
  },
  FILTER_ADD_COLLECTION: (state, collection_id) => {
    if (Array.isArray(collection_id)) {
      state.imagesFilters.collection_id = [...state.imagesFilters.collection_id, ...collection_id];
    } else {
      if (!state.imagesFilters.collection_id.includes(collection_id)) {
        state.imagesFilters.collection_id.push(parseInt(collection_id, 10));
      }
    }
  },
  FILTER_REPLACE_COLLECTION: (state, collection_id) => {
    const filteredOwnerId = Array.isArray(collection_id) ? collection_id.map(val => parseInt(val, 10)) : [parseInt(collection_id, 10)];
    state.imagesFilters.collection_id = filteredOwnerId;
  },
  FILTER_REMOVE_COLLECTION: (state, collection_id) => {
    let filtered = [];
    if (Array.isArray(collection_id)) {
      filtered = state.imagesFilters.collection_id.filter(val => !collection_id.includes(val));
    } else {
      filtered = state.imagesFilters.collection_id.filter(val => val !== collection_id);
    }
    state.imagesFilters.collection_id = filtered;
  },
  FILTER_REMOVE_ALL_COLLECTIONS: state => {
    state.imagesFilters.collection_id = [];
  },
  FILTER_ADD_OWNER: (state, owner_id) => {
    if (!state.imagesFilters.owner_id.includes(owner_id)) {
      state.imagesFilters.owner_id.push(parseInt(owner_id, 10));
    }
  },
  FILTER_REPLACE_OWNER: (state, owner_id) => {
    const filteredOwnerId = Array.isArray(owner_id) ? owner_id.map(val => parseInt(val, 10)) : [parseInt(owner_id, 10)];
    state.imagesFilters.owner_id = filteredOwnerId;
  },
  FILTER_REMOVE_OWNER: (state, owner_id) => {
    const filtered = state.imagesFilters.owner_id.filter(val => val !== owner_id);
    state.imagesFilters.owner_id = filtered;
  },
  FILTER_REMOVE_ALL_OWNERS: state => {
    state.imagesFilters.owner_id = [];
  },
  SET_STATE_GEOLOC: (state, modeContribute) => {
    state.imagesFilters.state = modeContribute ? ['initial', 'waiting_alignment'] : ['validated'];
  },
  SET_FILTER_FROM: (state, date_shot_min) => {
    state.imagesFilters.date_shot_min = date_shot_min;
  },
  SET_FILTER_TO: (state, date_shot_max) => {
    state.imagesFilters.date_shot_max = date_shot_max;
  },
  FILTER_REMOVE_KEYWORD: state => {
    state.imagesFilters.keyword = null;
  },
  FILTER_REPLACE_KEYWORD: (state, keyword) => {
    state.imagesFilters.keyword = keyword;
  },
  FILTER_ADD_PLACENAME: (state, placename) => {
    if (!state.imagesFilters.place_names.includes(placename)) {
      state.imagesFilters.place_names.push(placename);
    }
  },
  FILTER_REPLACE_PLACENAME: (state, placename) => {
    const filteredPlaceName = Array.isArray(placename) ? placename : [placename];
    state.imagesFilters.place_names = filteredPlaceName;
  },
  FILTER_REMOVE_PLACENAME: (state, placename) => {
    const filtered = state.imagesFilters.place_names.filter(val => val !== placename);
    state.imagesFilters.place_names = filtered;
  },
  SET_SORT_KEY: (state, key) => {
    state.imagesFilters.sortKey = key;
  },
  ADD_VIEWS_VISIT: (state, image_id) => {
    if (!state.views.visit.includes(image_id)) {
      state.views.visit.push(image_id);
    }
  },
  ADD_VIEWS_CONTRIBUTE: (state, image_id) => {
    if (!state.views.contribute.includes(image_id)) {
      state.views.contribute.push(image_id);
    }
  },
  SAVE_MAP_SEARCH_LOCATION: (state, payload) => {
    state.map.locationSearch = payload;
  },
  SAVE_MAP_CENTER: (state, payload) => {
    state.map.location = payload;
  },
  SAVE_MAP_ZOOM: (state, payload) => {
    state.map.zoom = payload;
  },
  TOGGLE_SLIDER: state => {
    state.slider.opened = !state.slider.opened;
  },
  SET_SLIDER_CURRENT_IMAGE_ID: (state, id) => {
    state.slider.currentId = id;
  },
  RESET_SLIDER_CURRENT_IMAGE_ID: state => {
    state.slider.currentId = null;
  },
  ADD_MAP_OVERLAY_FILTER: (state, layer) => {
    const mapLayer = {
      index: layer.index,
      id: layer.id,
      buildingDateKey: layer.buildingDateKey ?? 'year_built',
      maxDistanceFilter: layer.maxDistanceFilter,
      maxYearFilter: layer.maxYearFilter,
      url: layer.url,
      buildingLatitudeKey: layer.buildingLatitudeKey ?? 'Latitude',
      buildingLongitudeKey: layer.buildingLongitudeKey ?? 'Longitude',
      filterEnabled: layer.filterEnabled ?? false
    };
    state.mapOverlayFilters.push(mapLayer);
  },
  REMOVE_MAP_OVERLAY_FILTER: (state, id) => {
    state.mapOverlayFilters = state.mapOverlayFilters.filter(layer => layer.id !== id);
  },
  UPDATE_MAP_OVERLAY_FILTER_DISTANCE: (state, { id, distance }) => {
    const overlayFilter = state.mapOverlayFilters.find(layer => layer.id === id);
    overlayFilter.maxDistanceFilter = distance;
  },
  UPDATE_MAP_OVERLAY_FILTER_YEAR: (state, { id, year }) => {
    const overlayFilter = state.mapOverlayFilters.find(layer => layer.id === id);
    overlayFilter.maxYearFilter = year;
  },
  SET_MAP_MODE: (state, mode) => {
    state.map.mode = MapModes[mode];
  },
  SET_IMAGE_TYPES: (state, types) => {
    state.imagesFilters.view_type = [ ...types ];
  },
  SET_POI: (state, poi) => {
    state.imagesFilters.POI_latitude = poi.latitude;
    state.imagesFilters.POI_longitude = poi.longitude;
    state.imagesFilters.POI_MaxDistance = poi.distance;
  },
  RESET_POI: (state) => {
    state.imagesFilters.POI_latitude = 0;
    state.imagesFilters.POI_longitude = 0;
    state.imagesFilters.POI_MaxDistance = 2000;
  },
};

const actions = {
  loadImageVisit({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('RESET_POSES');
      getAttributes(params)
        .then(response => {
          const { pose, poses, ...image } = response.data;
          commit('SET_CURRENT_IMAGE', image);
          commit('SET_POSE', pose);
          if (poses && poses.length > 1) {
            commit('SET_POSES', poses);
            //set panoramas
            commit('SET_CURRENT_PANORAMA', poses);
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  //action: loadSelectedPose
  updateCurrentPose({ commit }, geolocId) {
    commit('UPDATE_CURRENT_POSE', geolocId);
  },
  loadImageContribute({ commit }, params) {
    return new Promise((resolve, reject) => {
      getAttributes(params)
        .then(response => {
          commit('SET_CURRENT_IMAGE', response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  loadImagesSlider({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const defaultParams = { ...state.imagesFilters };
      // POI (point of interest) only used in loadImagesFromGeoPoint
      delete defaultParams.POI_latitude;
      delete defaultParams.POI_longitude;
      delete defaultParams.POI_MaxDistance;

      const apiParams = { ...defaultParams, ...params };
      // There are two modes for images: slider at the bottom of the page or gallery view
      // For more than 100 images queried, we switch to gallery view
      const isGalleryViewActive = apiParams.limit >= 100;

      if (!state.slider.opened && !isGalleryViewActive) {
        // no need to fetch images when slider and gallery view are closed
        resolve();
        return;
      }

      getImages(apiParams)
        .then(response => {
          const images = response.data.rows;
          commit('SET_CURRENT_IMAGES', images);
          commit('SET_CURRENT_IMAGES_NUMBER', response.data.count);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  loadAddImageToSlider({ commit }, params) {
    return new Promise((resolve, reject) => {
      getAttributes(params) //TODO remove unneeded attributes when the API will support flexible way to get attribute
        .then(response => {
          const image = response.data;
          commit('ADD_TO_CURRENT_IMAGES', image);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  loadMarkersKeywordFiltered({ state }) {
    return new Promise((resolve, reject) => {
      const defaultParams = { ...state.imagesFilters };
      // POI (point of interest) only used in loadImagesFromGeoPoint
      delete defaultParams.POI_latitude;
      delete defaultParams.POI_longitude;
      delete defaultParams.POI_MaxDistance;

      getImagesId({ ...defaultParams, limit: 100000 })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  loadImageFootprint({ commit }, { params, cancelToken }) {
    return new Promise((resolve, reject) => {
      getFootprint(params, cancelToken)
        .then(response => {
          commit('SET_CURRENT_IMAGE_FOOTPRINT', response.data.footprint);
          resolve();
        })
        .catch(error => {
          // Hide cancelled request from errors
          if (!error?.__CANCEL__) {
            reject(error);
          }
        });
    });
  },

  updateUrl({ state }) {
    const collection_id = state.imagesFilters.collection_id;
    const owner_id = state.imagesFilters.owner_id;
    const date_shot_min = state.imagesFilters.date_shot_min;
    const date_shot_max = state.imagesFilters.date_shot_max;
    const keyword = state.imagesFilters.keyword;

    const [longitude, latitude] = state.map.location;
    const zoom = state.map.zoom;

    const hash = (Number.isFinite(latitude) && Number.isFinite(longitude) && Number.isFinite(zoom))
      ? `#${zoom}/${latitude}/${longitude}`
      : '';

    const query = {
      ...(Array.isArray(collection_id) && collection_id.length > 0 && { collections: collection_id }),
      ...(!Array.isArray(collection_id) && { collections: [collection_id] }),
      ...(Array.isArray(owner_id) && owner_id.length > 0 && { owners: owner_id }),
      ...(!Array.isArray(owner_id) && { owners: [owner_id] }),
      ...(date_shot_min && { date_shot_min }),
      ...(date_shot_max && { date_shot_max }),
      ...(keyword && { keyword })
    };

    router.replace({ hash, query }).catch(err => {
      console.info(err);
    });
  },

  updateFilters({ state, commit, dispatch, rootState }, { updateUrl, replace, reloadImages, ...filters }) {
    const isFiltersEmpty = Object.entries(filters).length === 0 && filters.constructor === Object;
    commit('SET_STATE_GEOLOC', rootState.app.modeContribute);

    if (filters?.limit >= 100 && state.slider.opened) {
      commit('TOGGLE_SLIDER');
    }
    if (filters?.offset !== undefined) {
      commit('SET_FILTER_OFFSET', filters.offset);
    }
    if (filters?.latitude !== undefined) {
      commit('SET_FILTER_LATITUDE', filters.latitude);
    }
    if (filters?.longitude !== undefined) {
      commit('SET_FILTER_LONGITUDE', filters.longitude);
    }
    if (filters?.bbox === null) {
      commit('SET_FILTER_BBOX', []);
    } else if (filters?.bbox) {
      commit('SET_FILTER_BBOX', filters.bbox);
    }
    if (filters?.limit) {
      commit('SET_FILTER_LIMIT', filters.limit);
    }
    if (filters?.collection_id === null) {
      commit('FILTER_REMOVE_ALL_COLLECTIONS');
    } else if (filters?.collection_id && !replace) {
      commit('FILTER_ADD_COLLECTION', filters.collection_id);
    } else if (filters?.collection_id && replace) {
      commit('FILTER_REPLACE_COLLECTION', filters.collection_id);
    }
    if (filters?.owner_id === null) {
      commit('FILTER_REMOVE_ALL_OWNERS');
    } else if (filters?.owner_id && !replace) {
      commit('FILTER_ADD_OWNER', filters.owner_id);
    } else if (filters?.owner_id && replace) {
      commit('FILTER_REPLACE_OWNER', filters.owner_id);
    }
    if (filters?.keyword) {
      commit('FILTER_REPLACE_KEYWORD', filters.keyword);
    }
    if (filters?.date_shot_min !== undefined) {
      commit('SET_FILTER_FROM', filters.date_shot_min);
    }
    if (filters?.date_shot_max !== undefined) {
      commit('SET_FILTER_TO', filters.date_shot_max);
    }
    if (filters?.sortKey) {
      commit('SET_SORT_KEY', filters.sortKey);
    }
    if (filters?.view_type) {
      commit('SET_IMAGE_TYPES', filters.view_type);
    }
    if (updateUrl && !isFiltersEmpty) {
      dispatch('updateUrl');
    }
    if (state.map.mode === MapModes.click) {
      if (state.imagesFilters.POI_latitude !== 0) {
        return new Promise((resolve) => {
          dispatch('loadImagesFromGeoPoint')
            .then(() => {
              resolve();
            });
        });
      } else {
        return;
      }
    }

    if (reloadImages) {
      return new Promise((resolve) => {
        dispatch('loadImagesSlider', { offset: filters?.offset })
          .then(() => {
            resolve();
          });
      });
    }
  },

  removeFilters({ state, commit, dispatch }, { updateUrl, reloadImages, ...filters }) {
    if (filters?.collection_id) {
      commit('FILTER_REMOVE_COLLECTION', filters.collection_id);
    }
    if (filters?.owner_id) {
      commit('FILTER_REMOVE_OWNER', filters.owner_id);
    }

    if (updateUrl) {
      dispatch('updateUrl');
    }
    if (state.map.mode === MapModes.click) {
      return new Promise((resolve) => {
        dispatch('loadImagesFromGeoPoint')
          .then(() => {
            resolve();
          });
      });
    }
    if (reloadImages) {
      return new Promise((resolve) => {
        dispatch('loadImagesSlider')
          .then(() => {
            resolve();
          });
      });
    }
  },

  removeAllFilters({ state, commit, dispatch }, { updateUrl, reloadImages, ...filters }) {
    if (filters?.collection_id) {
      commit('FILTER_REMOVE_ALL_COLLECTIONS');
    }
    if (filters?.owner_id) {
      commit('FILTER_REMOVE_ALL_OWNERS');
    }
    if (filters?.keyword) {
      commit('FILTER_REMOVE_KEYWORD');
    }
    if (updateUrl) {
      dispatch('updateUrl');
    }
    if (state.map.mode === MapModes.click) {
      return new Promise((resolve) => {
        dispatch('loadImagesFromGeoPoint')
          .then(() => {
            resolve();
          });
      });
    }
    if (reloadImages) {
      return new Promise((resolve) => {
        dispatch('loadImagesSlider')
          .then(() => {
            resolve();
          });
      });
    }
  },

  setMapSearchLocation({ }, location) { }, // Action used as event only

  saveMapLocation({ commit }, loc) {
    let { longitude, latitude, zoom } = loc;

    longitude = parseFloat(longitude.toFixed(4));
    latitude = parseFloat(latitude.toFixed(4));
    zoom = parseFloat(zoom.toFixed(2));

    if (longitude && latitude) {
      commit('SAVE_MAP_CENTER', [longitude, latitude]);
    }

    if (zoom) {
      commit('SAVE_MAP_ZOOM', zoom);
    }
  },

  toggleSlider({ commit }) {
    commit('TOGGLE_SLIDER');
  },

  setSliderCurrentImageId({ state, commit }, { id }) {
    if (state.slider.currentId !== id) {
      commit('SET_SLIDER_CURRENT_IMAGE_ID', id);
    }
  },

  resetSliderCurrentImageId({ state, commit }) {
    if (state.slider.currentId !== null) {
      commit('RESET_SLIDER_CURRENT_IMAGE_ID');
    }
  },

  addMapOverlayFilter({ state, commit }, { index, id, maxDistanceFilter, maxYearFilter, url, buildingDateKey, buildingLatitudeKey, buildingLongitudeKey, filterEnabled } ) {
    if (!state.mapOverlayFilters.find((overlay => overlay.id === id))) {
      commit('ADD_MAP_OVERLAY_FILTER', { index, id, maxDistanceFilter, maxYearFilter, url, buildingDateKey, buildingLatitudeKey, buildingLongitudeKey, filterEnabled });
    }
  },

  removeMapOverlayFilter({ state, commit }, id ) {
    if (state.mapOverlayFilters.find((overlay => overlay.id === id))) {
      commit('REMOVE_MAP_OVERLAY_FILTER', id);
    }
  },

  updateMapOverlayFilterDistance({ commit },  { id, distance }) {
    commit('UPDATE_MAP_OVERLAY_FILTER_DISTANCE', { id, distance });
  },

  updateMapOverlayFilterYear({ commit },  { id, year }) {
    commit('UPDATE_MAP_OVERLAY_FILTER_YEAR', { id, year });
  },

  fetchFootprintsAtPoint({ commit, dispatch },  { params }) {
    commit('SET_POI', {
      latitude: params.latitude,
      longitude: params.longitude,
      distance: params.distance
    });

    return new Promise((resolve) => {
      dispatch('loadImagesFromGeoPoint')
        .then(() => {
          resolve();
        });
    });
  },

  setMapMode({ commit }, { mode }) {
    if (MapModes.hasOwnProperty(mode)) {
      commit('SET_MAP_MODE', mode);
    } else {
      console.error(`Invalid map mode: ${mode}`);
    }
  },

  loadImagesFromGeoPoint({ commit, state }) {
    return new Promise((resolve, reject) => {
      const defaultParams = { ...state.imagesFilters };

      getImages(defaultParams)
        .then(response => {
          const images = response.data.rows;
          commit('SET_CURRENT_IMAGES', images);
          commit('SET_CURRENT_IMAGES_NUMBER', response.data.count);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  clearImages({ commit }) {
    commit('RESET_CURRENT_IMAGES');
  },

  resetPOI({ commit }) {
    commit('RESET_POI');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
